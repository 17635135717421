<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" />
    <feather-icon @click="playSimulation" icon="PlayIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" />
  </div>
</template>

<script>
export default {
  methods: {
    playSimulation() {
      this.$router.push(`/simulations/${this.params.data.id}/play`).catch(() => {});
    },
    editRecord() {
      this.$router.push(`/a/simulations/${this.params.data.id}`).catch(() => {});
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: 'Please confirm you want to archive this simulation.',
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {
      this.$vs.notify({
        color: 'danger',
        title: "Can't archive simulation",
        text: "At the moment simulations can't be archived. Contact +1-647-244-0868 or cameron@voriant.com",
        position: 'top-right',
      });

      //this.$http.delete(`simulations/${this.params.data.id}`)
      // .then(response => {
      //   if (response.status === 200) {
      //    this.params.loadProductBuffer();
      //   return this.$vs.notify({
      //     color: 'success',
      //     title: 'Course Archived',
      //      text: 'You have successfully archived this module',
      //      position: 'bottom-right',
      //     });
      //    }
      //     this.showError({response});
      //   })
      //   .catch(this.showError);
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this module';
      if (exception.response) {
        error = exception.response.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive simulation',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
