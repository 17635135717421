<template>
  <div class="flex items-center">
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary text-lg font-bold">{{
      params.value.length
    }}</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    url() {
      return `${this.params.data.id}`;
    },
  },
};
</script>
